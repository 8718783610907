import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "carouselExampleCaptions",
  class: "carousel slide carousel-fade mx-auto",
  "data-bs-ride": "carousel"
}
const _hoisted_2 = { class: "carousel-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarousellComponent = _resolveComponent("CarousellComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.photos, (photo) => {
        return (_openBlock(), _createBlock(_component_CarousellComponent, {
          key: photo,
          content: photo.jumbotron.content,
          learnMoreLink: photo.jumbotron.learnMoreLink,
          title: photo.jumbotron.title,
          url: photo.url,
          isActive: photo.isActive
        }, null, 8, ["content", "learnMoreLink", "title", "url", "isActive"]))
      }), 128))
    ]),
    _cache[0] || (_cache[0] = _createStaticVNode("<button class=\"carousel-control-prev\" type=\"button\" data-bs-target=\"#carouselExampleCaptions\" data-bs-slide=\"prev\" data-v-cc3dd380><span class=\"carousel-control-prev-icon\" aria-hidden=\"true\" data-v-cc3dd380></span><span class=\"visually-hidden\" data-v-cc3dd380>Previous</span></button><button class=\"carousel-control-next\" type=\"button\" data-bs-target=\"#carouselExampleCaptions\" data-bs-slide=\"next\" data-v-cc3dd380><span class=\"carousel-control-next-icon\" aria-hidden=\"true\" data-v-cc3dd380></span><span class=\"visually-hidden\" data-v-cc3dd380>Next</span></button>", 2))
  ]))
}