import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "carousel-caption" }
const _hoisted_3 = {
  class: "bg-dark rounded-3",
  style: {"--bs-bg-opacity":"0.7"}
}
const _hoisted_4 = { class: "container-fluid py-3" }
const _hoisted_5 = { class: "h3 fw-bold d-none d-md-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($props.isActive ? 'carousel-item active' : 'carousel-item')
  }, [
    _createElementVNode("img", {
      src: `${$data.publicPath}${$props.url}`,
      class: "d-block w-100",
      alt: $props.url
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString($props.title), 1),
          _createElementVNode("p", null, _toDisplayString($props.content), 1),
          _createVNode(_component_router_link, {
            class: "btn btn-success btn-sm",
            type: "button",
            to: $props.learnMoreLink
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Saber Más ")
            ])),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ])
  ], 2))
}