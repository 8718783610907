<template>
    <form @submit.prevent="onSubmit" class="contactForm" ref="contactForm">
        <div class="row">
            <div class="col-md-12" v-for="(field, index) in fields" :key="index">
                <div class="form-group contact-form-group">
                    <input v-if="field.type !== 'textarea'" :type="field.type" class="form-control contact-form-input"
                        :name="field.name" v-model="localFormData[field.name]" :placeholder="field.placeholder"
                        required />
                    <textarea v-else class="form-control contact-form-input" :name="field.name"
                        v-model="localFormData[field.name]" :placeholder="field.placeholder" rows="4"
                        required></textarea>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group contact-form-group">
                    <input type="submit" value="Enviar mensaje" class="btn submit-button contact-form-btn" />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        fields: Array,
        formData: Object,
    },
    data() {
        return {
            localFormData: { ...this.formData }, // Create a local copy of formData
        };
    },
    methods: {
        onSubmit() {
            const form = this.$refs.contactForm;
            if (form.checkValidity()) {
                this.$emit('submit', this.localFormData);
            } else {
                form.reportValidity();
            }
        },
    },
};
</script>

<style scoped>
.contact-form-group {
    margin-bottom: 1rem;
}

.contact-form-input {
    transition: all 0.3s ease;
}

.contact-form-input:focus {
    border-color: #e6964c;
    box-shadow: 0 0 10px rgba(230, 150, 76, 0.5);
}

.submit-button {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
}

.submit-button:hover,
.submit-button:focus {
    background-color: #d68f3b;
    transform: scale(1.05);
}
</style>
