<template>
  <main>
    <HomeCarrousellComponent />
    <HomeTextComponent />

    <section class="ftco-section d-flex align-items-center"> <!-- Add Flexbox to center content -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <!-- Unified wrapper with shadow -->
            <div class="wrapper unified-container d-flex flex-lg-row flex-column align-items-stretch">
              <!-- Image section -->
              <div class="col-lg-6 d-flex align-items-stretch p-0 image-column">
                <img src="img/Home/performance.webp" class="img-fluid rounded-start full-height" alt="Buenos Aires" />
              </div>
              <!-- Contact form section -->
              <div class="col-lg-6 d-flex align-items-stretch contact-column">
                <div class="contact-wrap w-100 p-md-5 p-4">
                  <h3 class="contact-form-title">Contáctanos</h3>
                  <p class="mb-4">Nos encanta que nos escribas.</p>

                  <ContactInfo title="Email" :value="contactInfo.email" :link="'mailto:' + contactInfo.email" />
                  <ContactInfo title="Teléfono" :value="contactInfo.phone" :link="'tel://' + contactInfo.phone" />

                  <ContactForm :fields="formFields" :formData="formData" @submit="handleSubmit" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ContactModal ref="okModal" title="Mensaje enviado"
          message="Muchas gracias por contactarnos, Uno de nuestros tunos te contactará en breve."
          @close="modalClosed('ok')" />
        <ContactModal ref="errorModal" title="Mensaje No Enviado"
          message="Muchas gracias por contactarnos, tu mensaje no pudo ser enviado, pero puedes contactarnos a nuestro whatsapp o Correo Electrónico."
          @close="modalClosed('error')" />
      </div>
    </section>

  </main>
</template>

<script>
import { defineComponent } from "vue";
import HomeCarrousellComponent from "@/components/HomeCarrousellComponent.vue";
import HomeTextComponent from "@/components/Home/HomeTextComponent.vue";
import ContactInfo from '../components/Contact/ContactInfo.vue';
import ContactForm from '../components/Contact/ContactForm.vue';
import ContactModal from '../components/Contact/ContactModal.vue';

const url = "https://docs.google.com/forms/u/0/d/e/1FAIpQLScboVJWfVoXApY8ANyjMSH6oGEhfT-hAP4JK2yvL0_f816m1Q/formResponse";

export default defineComponent({
  name: "HomeView",
  components: {
    HomeCarrousellComponent,
    HomeTextComponent,
    ContactInfo,
    ContactForm,
    ContactModal,
  },
  data() {
    return {
      contactInfo: {
        email: "tuna@tunadelaean.com",
        phone: "+57 302 654 4545",
      },
      formFields: [
        { name: 'name', type: 'text', placeholder: 'Nombre' },
        { name: 'emailAddress', type: 'email', placeholder: 'Correo Electrónico' },
        { name: 'phone', type: 'text', placeholder: 'Teléfono' },
        { name: 'message', type: 'textarea', placeholder: 'Ingresa tu mensaje' },
      ],
      formData: {
        name: "",
        emailAddress: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    async handleSubmit(data) {
      try {
        await fetch(url, {
          method: "POST",
          mode: "no-cors",
          body: this.getInputData(data),
        });
        this.$refs.okModal.showModal();
        this.resetFormData(data);
      } catch (err) {
        this.$refs.errorModal.showModal();
      }
    },
    resetFormData(data) {

      data.name = "";
      data.emailAddress = "";
      data.phone = "";
      data.message = "";
    },
    getInputData(data) {
      let formData = new FormData();
      // Entries names are given by google
      formData.append("entry.2401544", data.name);
      formData.append("emailAddress", data.emailAddress);
      formData.append("entry.2216643", data.phone);
      formData.append("entry.2064543", data.message);
      return formData;
    },
    modalClosed(type) {
      console.log(`${type} modal closed`);
    },
  },
  metaInfo: {
    title: "Home",
  },
});
</script>

<style scoped>
/* Unified container for image and form with shared shadow and border */
.unified-container {
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
}

.image-column img {
  height: 100%;
  object-fit: cover;
  /* Ensure image fills the height and is properly contained */
}

.contact-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.contact-wrap {
  border: 0px solid #ddd;
}

.contact-form-title {
  color: #e6964c;
  font-weight: bolder;
}

/* Make the section take the full height of the viewport */
.ftco-section {
  min-height: 50vh;
}
</style>
