<template>
  <WhatsappButton phone="573026544545" />
  <NavBar />
  <CustomFooter />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import NavBar from "@/components/NavBar.vue"; // @ is an alias to /src
import CustomFooter from "@/components/CustomFooter.vue";
import WhatsappButton from "@/components/WhatsappButton.vue";

export default defineComponent({
  name: "App",
  components: {
    NavBar,
    CustomFooter,
    WhatsappButton,
  },
});
</script>
