import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.whatsappLink,
    class: "whatsapp",
    target: "_blank"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("i", { class: "fa fa-whatsapp whatsapp-icon" }, null, -1)
  ]), 8, _hoisted_1))
}