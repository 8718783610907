<template>
  <a :href="whatsappLink" class="whatsapp" target="_blank">
    <i class="fa fa-whatsapp whatsapp-icon"></i>
  </a>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "WhatsappButton",
  props: {
    phone: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // Explicit return type for the computed property
    const whatsappLink = computed<string>(() => {
      return `https://wa.me/${props.phone}?text=Hola,%20estoy%20interesado%20en%20contratar%20una%20serenata%20en%20Bogot%C3%A1%20con%20la%20Tuna%20de%20la%20EAN.%20%C2%BFPodr%C3%ADan%20darme%20m%C3%A1s%20informaci%C3%B3n%3F`;
    });

    return {
      whatsappLink
    };
  }
});
</script>

<style>
.whatsapp {
  position: fixed;
  width: 5rem;
  height: 5rem;
  bottom: 3rem;
  right: 3rem;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 3rem;
  z-index: 100;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  animation: bounce 2s infinite;
}

.whatsapp:hover {
  background-color: #d68f3b;
  transform: scale(1.5);
}

.whatsapp-icon {
  margin-top: 1rem;
  margin-left: 0.1rem;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
    /* Adjust the value for higher or lower bounce */
  }

  60% {
    transform: translateY(-10px);
  }
}
</style>
