import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatsappButton = _resolveComponent("WhatsappButton")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_CustomFooter = _resolveComponent("CustomFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WhatsappButton, { phone: "573026544545" }),
    _createVNode(_component_NavBar),
    _createVNode(_component_CustomFooter)
  ], 64))
}