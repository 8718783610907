<template>
    <div class="row mb-6">
        <div class="col-md-6">
            <div class="dbox w-100 d-flex align-items-start">
                <div class="text">
                    <p>
                        <span>{{ title }}:</span>
                        <a class="font-weight-bold contact-link" :href="link">{{ value }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        value: String,
        link: String,
    },
};
</script>

<style scoped>
.contact-link {
    position: relative;
    color: #007bff;
    font-weight: bold;
    padding: 5px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    left: 0;
    bottom: 0;
    background-color: rgb(207, 123, 22);
    transition: width 0.3s ease;
}

.contact-link:hover {
    color: #e6964c;
}

.contact-link:hover::after {
    width: 100%;
}
</style>