<template>
  <div class="container">
    <footer class="
        d-flex
        flex-wrap
        justify-content-between
        align-items-center
        py-3
        my-4
        border-top
      ">
      <div class="col-md-4 d-flex align-items-center">
        <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          <img src="../assets/logo.svg" alt="Logo Tuna" width="30" height="24" />
        </a>
        <span class="text-muted">© 2024 Tuna de la Ean</span>
      </div>

      <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li class="ms-3">
          <a class="text-muted" href="https://www.youtube.com/channel/UC945lehZOCvSNownsAjmhAQ" target="_blank"
            aria-label="YouTube Channel">
            <i class="fa fa-youtube footer-icon" aria-hidden="true"></i>
          </a>
        </li>
        <li class="ms-3">
          <a class="text-muted" href="https://www.instagram.com/tunadelaean/" target="_blank"
            aria-label="Instagram Profile">
            <i class="fa fa-instagram footer-icon" aria-hidden="true"></i>
          </a>
        </li>
        <li class="ms-3">
          <a class="text-muted" href="https://www.facebook.com/tunadelaean" target="_blank" aria-label="Facebook Page">
            <i class="fa fa-facebook footer-icon" aria-hidden="true"></i>
          </a>
        </li>
        <li class="ms-3">
          <a class="text-muted" href="https://twitter.com/tunadelaean" target="_blank" aria-label="Twitter Profile">
            <i class="fa fa-twitter footer-icon" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomFooter",
  props: {
    msg: String,
  },
});
</script>

<style scoped>
.footer-icon {
  font-size: 2rem;
  transition: transform 0.2s, color 0.2s;
  /* Hover effect transition */
}

.footer-icon:hover {
  transform: scale(1.2);
  color: rgb(207, 123, 22);
  /* Hover color */
}

@media (max-width: 576px) {
  .footer-icon {
    font-size: 1.2rem;
    /* Adjust size for smaller screens */
  }
}
</style>
