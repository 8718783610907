<template>
    <div class="main-text text-center container">
        <p class="mt-2">Estamos encantados de tenerte aquí y agradecemos tu interés en nuestros productos. Nuestra misión es
            ofrecerte
            experiencias inolvidables con nuestras serenatas y detalles únicos. ¡Gracias por elegirnos!</p>
    </div>
</template>

<style scoped>
.main-text {
    font-size: 2.8vw;
}
</style>