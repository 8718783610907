import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundComponent from '@/components/NotFoundComponent.vue'
import { useHead } from '@unhead/vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Tuna de la Universidad EAN - Home',
      description: 'Contrata a la Tuna de la Universidad EAN para presentaciones inolvidables. Música tradicional y entretenimiento para eventos especiales.',
      ogImage: '/img/BgPhotos/buenosAires.jpg'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'Sobre Nosotros - Tuna de la Universidad EAN',
      description: 'Descubre la historia y trayectoria de la Tuna de la Universidad EAN, una agrupación musical con más de 20 años de tradición.',
      ogImage: '/img/BgPhotos/buenosAires.jpg'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta: {
      title: 'Contacto - Tuna de la Universidad EAN',
      description: '¿Quieres contratar una presentación privada? Contáctanos y te responderemos lo antes posible para personalizar tu evento.',
      ogImage: '/img/BgPhotos/duitama.jpg'
    }
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/ServicesView.vue'),
    meta: {
      title: 'Servicios - Tuna de la Universidad EAN',
      description: 'Ofrecemos actuaciones privadas para bodas, eventos corporativos y fiestas. Disfruta de la música y alegría de la Tuna de la Universidad EAN.',
      ogImage: '/img/BgPhotos/lima.jpg'
    }
  },
  {
    path: '/listenus',
    name: 'listenus',
    component: () => import('../views/ListenUsView.vue'),
    meta: {
      title: 'Escúchanos - Tuna de la Universidad EAN',
      description: 'Escucha nuestra música en línea. Disfruta de las mejores interpretaciones de la Tuna de la Universidad EAN en eventos y presentaciones.',
      ogImage: '/img/BgPhotos/tabio.jpg'
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notFound',
    component: NotFoundComponent,
    meta: {
      title: 'Página No Encontrada - Tuna de la Universidad EAN',
      description: 'La página que buscas no existe. Regresa a la página principal para descubrir más sobre la Tuna de la Universidad EAN.',
      ogImage: '/img/BgPhotos/buenosAires.jpg'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Use beforeEach hook to dynamically update meta tags
router.beforeEach((to, from, next) => {
  // Type assertion to ensure TypeScript understands meta structure
  const meta = to.meta as {
    title?: string
    description?: string
    ogImage?: string
  }

  useHead({
    title: meta.title || 'Tuna de la Universidad EAN',
    meta: [
      { name: 'description', content: meta.description || 'Descubre la musica y las serenatas de la Tuna de la Universidad EAN.' },
      { property: 'og:title', content: meta.title || 'Tuna de la Universidad EAN' },
      { property: 'og:description', content: meta.description || 'Contrata a la Tuna de la Universidad EAN para eventos especiales.' },
      { property: 'og:image', content: meta.ogImage || '/img/BgPhotos/buenosAires.jpg' },
      { name: 'keywords', content: 'serenata, serenata de cumpleaños,tuna,serenata bogota,serenata tuna',},
      { name: 'geo.region', content: 'CO-DC' },
      { name: 'geo.placename', content: 'Bogotá' },
      { name: 'geo.position', content: '4.7110;-74.0721' },
      { name: 'ICBM', content: '4.7110, -74.0721' }
    ]
  })

  next()
})

export default router
