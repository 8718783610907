import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo_white.svg'


const _hoisted_1 = { class: "navbar navbar-expand-lg sticky-top navbar-dark bg-dark" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = {
  class: "collapse navbar-collapse justify-content-end",
  id: "navbarSupportedContent"
}
const _hoisted_4 = { class: "navbar-nav" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          class: "navbar-brand",
          to: "/"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              alt: "Vue logo",
              class: "navbar-logo",
              src: _imports_0
            }, null, -1),
            _createTextVNode(" Tuna de la EAN ")
          ])),
          _: 1
        }),
        _cache[4] || (_cache[4] = _createElementVNode("button", {
          class: "navbar-toggler",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }, [
          _createElementVNode("span", { class: "navbar-toggler-icon" })
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_router_link, { to: "/services" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Servicios")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, { to: "/about" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Acerca De")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, { to: "/contact" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Contacto")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "slide",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ], 64))
}