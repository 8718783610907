<template>
  <nav class="navbar navbar-expand-lg sticky-top navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img alt="Vue logo" class="navbar-logo" src="../assets/logo_white.svg" />
        Tuna de la EAN
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/services">Servicios</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/listenus">Escuchanos</router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/about">Acerca De</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact">Contacto</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <router-view v-slot="{ Component }">
    <transition name="slide" mode="out-in">
      <Component :is="Component" />
    </transition>
  </router-view>

</template>

<style scoped>
.navbar-logo {
  max-height: 50px;
}

nav a {
  font-weight: bold;
  color: #afbac5;
  padding: 5px;
  text-decoration: none;
  position: relative;
}

nav a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: rgb(207, 123, 22);
  transition: width 0.3s ease;
}

.nav-item{
  padding-left: 0.5em;
  padding-right: 0.5em;
}

nav a:hover::after {
  width: 100%;
}

nav a.router-link-exact-active {
  color: #e6964c;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.3s ease-out;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBar",
  props: {
    msg: String,
  },
});
</script>