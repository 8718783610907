import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.svg'


const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<footer class=\"d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top\" data-v-37b04e36><div class=\"col-md-4 d-flex align-items-center\" data-v-37b04e36><a href=\"/\" class=\"mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1\" data-v-37b04e36><img src=\"" + _imports_0 + "\" alt=\"Logo Tuna\" width=\"30\" height=\"24\" data-v-37b04e36></a><span class=\"text-muted\" data-v-37b04e36>© 2024 Tuna de la Ean</span></div><ul class=\"nav col-md-4 justify-content-end list-unstyled d-flex\" data-v-37b04e36><li class=\"ms-3\" data-v-37b04e36><a class=\"text-muted\" href=\"https://www.youtube.com/channel/UC945lehZOCvSNownsAjmhAQ\" target=\"_blank\" aria-label=\"YouTube Channel\" data-v-37b04e36><i class=\"fa fa-youtube footer-icon\" aria-hidden=\"true\" data-v-37b04e36></i></a></li><li class=\"ms-3\" data-v-37b04e36><a class=\"text-muted\" href=\"https://www.instagram.com/tunadelaean/\" target=\"_blank\" aria-label=\"Instagram Profile\" data-v-37b04e36><i class=\"fa fa-instagram footer-icon\" aria-hidden=\"true\" data-v-37b04e36></i></a></li><li class=\"ms-3\" data-v-37b04e36><a class=\"text-muted\" href=\"https://www.facebook.com/tunadelaean\" target=\"_blank\" aria-label=\"Facebook Page\" data-v-37b04e36><i class=\"fa fa-facebook footer-icon\" aria-hidden=\"true\" data-v-37b04e36></i></a></li><li class=\"ms-3\" data-v-37b04e36><a class=\"text-muted\" href=\"https://twitter.com/tunadelaean\" target=\"_blank\" aria-label=\"Twitter Profile\" data-v-37b04e36><i class=\"fa fa-twitter footer-icon\" aria-hidden=\"true\" data-v-37b04e36></i></a></li></ul></footer>", 1)
  ])))
}